import {
  DeepPartial,
  FieldErrors,
  ResolverResult,
  UnpackNestedValue,
} from 'react-hook-form';

import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  getServerTime,
  isAfter,
  isBefore,
  isSameDay,
  parseIsoDate,
  startOfDay,
} from '../../../../utils/dateUtil';
import { parseAndValidateCorrectTimeAndDivisibleBy5Minutes } from '../../../../utils/dateValidator';

export const MASS_CANCEL_ALL_CALENDAR_TEMPLATES_VALUE = '-1';

export type MassCancelFormValues = {
  date: string;
  fullDay: boolean;
  fromTime: string;
  toTime: string;
  resourceTypeId: string;
  clearApptTemplate: boolean;
};

export const useMassCancelValidation = ({
  hasMultipleResourceTypes,
}: {
  hasMultipleResourceTypes: boolean;
}) => {
  const { t } = useCommonTranslation();
  return (
    submitValues: UnpackNestedValue<MassCancelFormValues>,
  ): ResolverResult<MassCancelFormValues> => {
    const errors: DeepPartial<FieldErrors<MassCancelFormValues>> = {};
    const serverNow = getServerTime();
    const todayStartOfDay = startOfDay(serverNow);
    let date: Date | undefined;
    if (!submitValues.date) {
      errors.date = { message: t('must-not-empty') };
    } else {
      date = parseIsoDate(submitValues.date);
      if (isBefore(date, todayStartOfDay)) {
        errors.date = {
          message: t('must-not-be-in-past'),
        };
      }
    }

    if (!submitValues.fullDay) {
      let fromTimeDate: Date | undefined = undefined;
      if (!submitValues.fromTime) {
        errors.fromTime = { message: t('must-not-empty') };
      } else {
        const { message, parsedDate } =
          parseAndValidateCorrectTimeAndDivisibleBy5Minutes({
            input: submitValues.fromTime,
            t,
          });
        if (message !== undefined) {
          errors.fromTime = { message };
        } else {
          if (
            date &&
            isSameDay(date, todayStartOfDay) &&
            isBefore(parsedDate, serverNow)
          ) {
            errors.fromTime = { message: t('must-be-in-future') };
          } else {
            fromTimeDate = parsedDate;
          }
        }
      }

      if (!submitValues.toTime) {
        errors.toTime = { message: t('must-not-empty') };
      } else {
        const { message, parsedDate } =
          parseAndValidateCorrectTimeAndDivisibleBy5Minutes({
            input: submitValues.toTime,
            t,
          });
        if (message !== undefined) {
          errors.toTime = { message };
        } else if (fromTimeDate && !isAfter(parsedDate, fromTimeDate)) {
          errors.toTime = {
            message: t('must-be-after', { column: t('time-from') }),
          };
        }
      }
    }

    if (hasMultipleResourceTypes && !submitValues.resourceTypeId) {
      errors.resourceTypeId = { message: t('must-not-empty') };
    }

    return {
      values: submitValues,
      errors,
    };
  };
};
