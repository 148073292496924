import CheckboxInput from 'components/common/Forms/CheckboxInput';
import {
  CommonInputHelperText,
  DialogFormWrapper,
  FormGroup,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import { CardLoader } from 'components/common/Loading';
import {
  AutoCompleteLabel,
  CheckboxesWithErrorWrapper,
  CheckboxListWrapper,
  GreenBoxWrapper,
} from 'components/views-components/un-auth/SignupForm/SignupForm.styled';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FaCalendarCheck } from 'react-icons/fa';

import Input from '../../../../components/common/Forms/Input';
import { SITE_WEEK_DAYS } from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { isBefore, WEEK_DAY_LABELS } from '../../../../utils/dateUtil';
import { UseGeneralSitePreferencesFormReturn } from './GeneralSitePreferencesHooks';
import { CHECKBOX_ATTRIBUTE_SEPARATOR } from '../../../../configs/constants';
import { parseAndValidateCorrectTimeAndDivisibleBy5Minutes } from '../../../../utils/dateValidator';

interface Props {
  isFetching: boolean;
  disabled: boolean;
  attributesForm: UseGeneralSitePreferencesFormReturn;
}

const AvailabilityForm: React.FC<Props> = ({
  isFetching,
  disabled,
  attributesForm,
}) => {
  const { t } = useCommonTranslation();

  const {
    formState: { errors },
    attributeRegister,
    control,
    register,
  } = attributesForm;
  const watchedValues = useWatch({ control });

  return (
    <DialogFormWrapper>
      <FormHeader>
        <FaCalendarCheck />
        <FormTitle>{t('availability')}</FormTitle>
      </FormHeader>
      <FormRow>
        <Input
          {...register('openHourTime', {
            validate: (value: string) => {
              return parseAndValidateCorrectTimeAndDivisibleBy5Minutes({
                input: value,
                t,
              }).message;
            },
            required: t('must-not-empty') as string,
          })}
          placeholder={t('opening-hours')}
          label={t('opening-hours')}
          hasError={Boolean(errors.openHourTime)}
          helperText={errors?.openHourTime?.message}
          disabled={disabled}
        />
        <Input
          {...register('closeHourTime', {
            validate: (value: string) => {
              const {
                message: timeAndMinutesError,
                parsedDate: parsedCloseHour,
              } = parseAndValidateCorrectTimeAndDivisibleBy5Minutes({
                input: value,
                t,
              });
              if (timeAndMinutesError !== undefined) return timeAndMinutesError;
              const openHour = watchedValues.openHourTime;
              if (openHour) {
                const { parsedDate: parsedOpenHour } =
                  parseAndValidateCorrectTimeAndDivisibleBy5Minutes({
                    input: openHour,
                    t,
                  });
                if (
                  parsedOpenHour &&
                  !isBefore(parsedOpenHour, parsedCloseHour)
                ) {
                  return t('open-close-hour-validation') as string;
                }
              }
            },
            required: t('must-not-empty') as string,
          })}
          placeholder={t('closing-hours')}
          label={t('closing-hours')}
          hasError={Boolean(errors?.closeHourTime)}
          helperText={errors?.closeHourTime?.message}
          disabled={disabled}
        />
      </FormRow>
      <FormRow>
        <FormGroup>
          <CheckboxesWithErrorWrapper>
            <GreenBoxWrapper>
              <AutoCompleteLabel as="span">{t('days')}:</AutoCompleteLabel>
              <CheckboxListWrapper>
                {WEEK_DAY_LABELS(t).map((day) => (
                  <CheckboxInput
                    {...attributeRegister(
                      SITE_WEEK_DAYS,
                      (e) => e.target.value,
                      {
                        validate: (value: []) => {
                          return (
                            value.length > 0 || (t('must-not-empty') as string)
                          );
                        },
                      },
                      true,
                    )}
                    labelPosition={'before'}
                    label={day.label.substring(0, 3)}
                    key={'week_days_' + day.backendDowIndex}
                    id={'week_days_' + day.backendDowIndex}
                    value={day.backendDowIndex}
                    checked={watchedValues.attributes
                      ?.find((atr) => atr.key === SITE_WEEK_DAYS)
                      ?.value_str?.includes(`${day.backendDowIndex}`)}
                    disabled={disabled}
                  />
                ))}
              </CheckboxListWrapper>
            </GreenBoxWrapper>
            {errors[
              (CHECKBOX_ATTRIBUTE_SEPARATOR + WEEK_DAY_LABELS) as 'attributes'
            ] && (
              <CommonInputHelperText hasError={true}>
                {
                  errors[
                    (CHECKBOX_ATTRIBUTE_SEPARATOR + WEEK_DAY_LABELS) as 'id'
                  ]?.message
                }
              </CommonInputHelperText>
            )}
          </CheckboxesWithErrorWrapper>
        </FormGroup>
      </FormRow>
      {isFetching && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
};
export default AvailabilityForm;
