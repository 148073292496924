import { GlobalAttribute } from 'types/common';

import {
  PRE_BOOK_CORRECT_ANSWER,
  PRE_BOOK_INCORRECT_ANSWER,
  PRE_BOOK_INCORRECT_ANSWER_MESSAGE,
  PRE_BOOK_QUESTION,
} from '../../../../configs/siteAndTrustAttributes';
import { usePatientBookingQuestionConfig } from '../../../../context/patient/modules/booking';
import {
  conditionalStringSiteAttribute,
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';
import { UseCustomSettingsFormHooks } from './CustomSettingsHooks';

const keys = siteAttributeKeysSubset([
  PRE_BOOK_QUESTION,
  PRE_BOOK_CORRECT_ANSWER,
  PRE_BOOK_INCORRECT_ANSWER,
  PRE_BOOK_INCORRECT_ANSWER_MESSAGE,
]);

const restAttributes: GlobalAttribute[] = keys.map((key) => ({ key }));

export const usePreBookQuestionFormHooks: UseCustomSettingsFormHooks = ({
  site,
}) => {
  const configuration = usePatientBookingQuestionConfig(site);

  return {
    restAttributes,
    defaultValues: {
      enablePreBookQuestion: configuration.enabled,
    },
    beforeSubmit: (updatedSite, formValues) => {
      const { enablePreBookQuestion } = formValues;
      return {
        ...updatedSite,
        attributes: updateSiteAttributes(
          updatedSite.attributes,
          formValues.attributes,
          keys,
          (key, siteValue, formValue) => {
            const updatedValue = formValue || siteValue;
            return conditionalStringSiteAttribute({
              key,
              value: updatedValue,
              condition: enablePreBookQuestion,
            });
          },
        ),
      };
    },
  };
};
