import styled from 'styled-components';
import { boxShadow } from 'styles/partials/boxShadow';
import {
  absoluteFullStretchBox,
  leftCenterAbsolutePosition,
} from 'styles/partials/positions';
import { toRemStr } from 'utils/helpers';

export const LCDStyled = styled.div`
  ${absoluteFullStretchBox};
  position: fixed; // do not scroll
  background-color: var(--greentercial);
  display: flex;
  flex-direction: column;
`;

export const LCDHeaderStyled = styled.div`
  ${boxShadow}
  position: relative;
  height: ${toRemStr(120)};
  background-color: var(--white);
  border-radius: 0 0 var(--s4) var(--s4);
  display: flex;
  flex-shrink: 0;
`;

export const LCDRunningTextBox = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  position: relative;
  overflow: hidden;
  // muted edges

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: var(--s5);
    border-radius: 0 0 var(--s4) var(--s4);
    z-index: 1;
  }

  &:after {
    right: 0;
    background: transparent
      linear-gradient(90deg, transparent 0%, var(--white) 100%) 0% 0% no-repeat
      padding-box;
  }

  &:before {
    left: 0;
    background: transparent
      linear-gradient(270deg, transparent 0%, var(--white) 100%) 0% 0% no-repeat
      padding-box;
  }
`;

export const LCDFooterStyled = styled.div`
  ${boxShadow}
  height: ${toRemStr(80)};
  background-color: var(--white);
  border-radius: var(--s4) var(--s4) 0px 0px;
  display: flex;
  align-items: center;
  padding: 0 var(--s4);
  justify-content: space-between;
  position: relative;
  flex-shrink: 0;
`;

export const LCDFooterTime = styled.div`
  font-size: var(--h2);
  color: var(--textsecondary);
  letter-spacing: 0.01px;
`;

export const LCDCheckinNumberBox = styled.div`
  ${leftCenterAbsolutePosition}
  ${boxShadow}
    position: absolute;
  border-radius: var(--s4) var(--s4) 0px 0px;
  background-color: var(--greenaccent);
  color: var(--white);
  height: 100%;
  padding: 0 var(--s3);
  font-size: var(--h1);
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0px;
`;
export const LCDCheckinNumber = styled.div`
  font-size: var(--fontxxl);
  font-weight: 600;
  margin-left: var(--s3);
`;

export const LCDContentStyled = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--s4);
`;

export const LCDRow = styled.div`
  ${boxShadow}
  background-color: var(--yellowaction);
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${toRemStr(100)};
  margin-top: var(--s4);
  font-size: var(--fontxxxl);
  text-transform: uppercase;
`;

export const LCDRowCubicle = styled.div`
  border: 10px solid var(--yellowaction);
  border-radius: ${toRemStr(100)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding: 0 var(--s4);
`;

export const LCDRowName = styled.div`
  padding-left: var(--s5);
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const LCDRowMessage = styled.div`
  margin-left: auto;
  padding-right: var(--s3);
  padding-left: var(--s3);
  text-transform: none;
  font-size: var(--fontxxl);
`;
