import { TFunction } from 'i18next';
import {
  FaChartBar,
  FaClipboardCheck,
  FaClipboardList,
  FaDoorOpen,
  FaHospital,
  FaKey,
  FaUser,
  FaUserCircle,
  FaUserCog,
} from 'react-icons/fa';
import {
  patientURL,
  STAFF_GP_SURGERIES_URL,
  STAFF_ICBS_URL,
  STAFF_OTHER_REFERRERS_URL,
} from 'routes/AppRoutes';
import { UserProfileResponse } from 'types/authentication';
import { ICardTabs, INavbarDropdown, MenuItemProps } from 'types/common';
import {
  GET_PATIENT_RELEASENOTE_ROLES,
  USER_ROLES_PATHS,
  UserRoleId,
} from './constants';
import { MultiRoleCheckFunction } from '../context/RoleContext';
import { canChangePassword } from '../utils/user';
import { identity } from 'lodash';
import { getLanguageLabel, languagesOptions } from '../i18n';
import { PatientNavbarLanguageIcon } from '../containers/patient/PatientNavBar.styled';
import { UseChangeLanguageReturn } from '../containers/patient/PatientNavBarHooks';

export const getRouteFromRole: (roleName: UserRoleId) => string = (roleId) =>
  USER_ROLES_PATHS[roleId].main_route;

export const StaffSettingsTabsTree: (t: TFunction) => ICardTabs[] = (t) => [
  {
    label: t('gp-surgeries'),
    to: STAFF_GP_SURGERIES_URL,
  },
  {
    label: t('ccg-list'),
    to: STAFF_ICBS_URL,
  },
  {
    label: t('other-referrers'),
    to: STAFF_OTHER_REFERRERS_URL,
  },
];

export const StaffUsersTabsTree: (
  hasAdminAccessToSelectedSite: boolean,
  t: TFunction,
) => ICardTabs[] = (hasAdminAccessToSelectedSite, t) => [
  {
    label: t('patients'),
    to: '/staff/settings/users/patients',
  },
  ...(hasAdminAccessToSelectedSite
    ? [
        {
          label: t('staff'),
          to: '/staff/settings/users/staff',
        },
      ]
    : []),
  {
    label: t('merge'),
    to: '/staff/settings/users/merge',
  },
];

export const AdminSideMenuTree: (t: TFunction) => MenuItemProps[] = (t) => [
  {
    to: '/admin/departments',
    icon: <FaHospital />,
    label: t('sites'),
  },
  {
    to: '/admin/audit',
    icon: <FaClipboardCheck />,
    label: t('audit'),
  },
  {
    to: '/admin/texts',
    icon: <FaClipboardList />,
    label: t('texts'),
  },
  {
    to: '/admin/users',
    icon: <FaUser />,
    label: t('users'),
  },
  {
    to: '/admin/statistics',
    icon: <FaChartBar />,
    label: t('statistics'),
  },
];

export const AdminNavbarDropdowns = (
  sangixUser: UserProfileResponse | undefined,
  logout: () => void,
) => [
  {
    label: sangixUser ? sangixUser?.firstname + ' ' + sangixUser?.lastname : '',
    icon: <FaUserCircle />,
    accentColor: 'var(--greenaccent)',
    secondaryColor: 'var(--greentercial)',
    links: [
      {
        label: 'Logout',
        icon: <FaDoorOpen />,
        onClickHandler: () => {
          logout();
        },
      },
    ],
  },
];

export const AdminSitesTabsTree: (t: TFunction) => ICardTabs[] = (t) => [
  {
    label: t('sites'),
    to: '/admin/departments',
    exact: true,
  },
  {
    label: t('trusts'),
    to: '/admin/departments/trusts',
  },
];

export const AdminTextsTabsTree: (t: TFunction) => ICardTabs[] = (t) => [
  {
    label: t('patient-release-notes'),
    to: '/admin/texts/patient',
    exact: true,
  },
  {
    label: t('staff-release-notes'),
    to: '/admin/texts/staff',
  },
  {
    label: t('gdpr'),
    to: '/admin/texts/gdpr',
  },
  {
    label: t('support'),
    to: '/admin/texts/support',
  },
];

export const AdminUsersTabsTree: (t: TFunction) => ICardTabs[] = (t) => [
  {
    label: t('patients'),
    to: '/admin/users/patients',
  },
  {
    label: t('staff'),
    to: '/admin/users/staff',
  },
];

export const PatientLanguageNavbarDropdown = ({
  t,
  language,
  inProgressChangingLanguageTo,
  setLanguage,
}: {
  t: TFunction;
} & UseChangeLanguageReturn): INavbarDropdown => ({
  label: getLanguageLabel(language) ?? language,
  icon: <PatientNavbarLanguageIcon $loading={!!inProgressChangingLanguageTo} />,
  accentColor: 'var(--blueprimary)',
  secondaryColor: 'var(--bluesecondary)',
  links: languagesOptions
    .filter(({ value }) => value !== language)
    .map(({ label, value }) => ({
      label,
      onClickHandler: () => setLanguage(value),
    })),
  autoWidth: true,
});

export const PatientNavbarDropdowns = ({
  sangixUser,
  logout,
  hasAnyOfRoles,
  t,
  language,
  inProgressChangingLanguageTo,
  setLanguage,
}: {
  sangixUser: UserProfileResponse;
  logout: () => void;
  t: TFunction;
  hasAnyOfRoles: MultiRoleCheckFunction;
} & UseChangeLanguageReturn): INavbarDropdown[] => [
  PatientLanguageNavbarDropdown({
    t,
    language,
    inProgressChangingLanguageTo,
    setLanguage,
  }),
  {
    label: sangixUser.firstname + ' ' + sangixUser.lastname,
    icon: <FaUserCircle />,
    accentColor: 'var(--greenaccent)',
    secondaryColor: 'var(--greentercial)',
    links: [
      {
        label: t('my-profile'),
        icon: <FaUserCog />,
        onClickHandler: `${patientURL}/profile`,
      },
      ...(canChangePassword(sangixUser)
        ? [
            {
              label: t('password'),
              icon: <FaKey />,
              onClickHandler: `${patientURL}/change-password`,
            },
          ]
        : []),
      ...(hasAnyOfRoles(...GET_PATIENT_RELEASENOTE_ROLES)
        ? [
            {
              label: t('release-notes'),
              icon: <FaClipboardList />,
              onClickHandler: `${patientURL}/release-notes`,
            },
          ]
        : []),
      {
        label: t('logout'),
        icon: <FaDoorOpen />,
        onClickHandler: () => {
          logout();
        },
      },
    ],
  },
];

export const PatientUnAuthNavbarDropdowns = (
  params: {
    t: TFunction;
  } & UseChangeLanguageReturn,
): INavbarDropdown[] => [PatientLanguageNavbarDropdown(params)];

export const ProfileTabsTree: (t: TFunction) => ICardTabs[] = (t) => [
  {
    label: t('profile'),
    to: `${patientURL}/profile`,
    exact: true,
  },
  {
    label: t('shared-accounts'),
    to: `${patientURL}/shared-accounts`,
    exact: true,
  },
  {
    label: t('settings'),
    to: `${patientURL}/profile/settings`,
    exact: true,
  },
];

export const STAFF_ENTERED_CUBICLE_URL = '/staff/cubicle/entered';
export const STAFF_ENTERED_CUBICLE_TABS = ['queue', 'next'] as const;
export type StaffEnteredCubicleTab =
  (typeof STAFF_ENTERED_CUBICLE_TABS)[number];

export const StaffEnteredCubicleTabsTree: (t: TFunction) => ICardTabs[] = (
  t,
) => [
  {
    label: t('entered-cubicle-queue'),
    to: `${STAFF_ENTERED_CUBICLE_URL}/${identity<StaffEnteredCubicleTab>(
      'queue',
    )}`,
    exact: true,
  },
  {
    label: t('entered-cubicle-next'),
    to: `${STAFF_ENTERED_CUBICLE_URL}/${identity<StaffEnteredCubicleTab>(
      'next',
    )}`,
    exact: true,
  },
];

export const StaffSettingsEmailsTree: (
  t: TFunction,
  hideWaitingList: boolean,
) => ICardTabs[] = (t, hideWaitingList) => [
  {
    label: t('email-footer'),
    to: '/staff/settings/emails/footer',
    exact: true,
  },
  {
    label: t('email-confirmation'),
    to: '/staff/settings/emails/confirmation',
  },
  {
    label: t('email-cancel-appt'),
    to: '/staff/settings/emails/cancel-appointment',
  },
  {
    label: t('email-reminder'),
    to: '/staff/settings/emails/reminder',
  },
  {
    label: t('email-create-acc'),
    to: '/staff/settings/emails/create-account',
  },
  ...(!hideWaitingList
    ? [
        {
          label: t('email-waiting-list'),
          to: '/staff/settings/emails/waiting-list',
        },
      ]
    : []),
  {
    label: t('email-post-appt'),
    to: '/staff/settings/emails/post-appointment',
  },
];

export const StaffSettingsCubicleTree: (t: TFunction) => ICardTabs[] = (t) => [
  {
    label: t('cubicles'),
    exact: true,
    to: '/staff/settings/cubicle',
  },
  {
    label: t('calendar-templates'),
    exact: true,
    to: '/staff/settings/cubicle/calendar-templates',
  },
];

export const StaffSettingsTimePlanningTree: (t: TFunction) => ICardTabs[] = (
  t,
) => [
  {
    label: t('default'),
    exact: true,
    to: '/staff/settings/time-planning',
  },
  {
    label: t('date-picker'),
    exact: true,
    to: '/staff/settings/time-planning/date-picker',
  },
  {
    label: t('bank-holidays'),
    exact: true,
    to: '/staff/settings/time-planning/bank-holidays',
  },
  {
    label: t('mass-cancel'),
    exact: true,
    to: '/staff/settings/time-planning/mass-cancel',
  },
];
