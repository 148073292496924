import React, { useCallback, useMemo } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { CommonButton } from '../../../../components/common/Forms/Button';
import { ConfirmDialog } from '../../../../components/Popup/ConfirmDialog/ConfirmDialog';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useMutateMassCancelAppointment } from '../../../../query/appointments';
import { UserData } from '../../../../types/users';
import {
  formatDateOnlyISO,
  formatDateOnlySite,
  parseIsoDate,
  parseTimeInHHmmToSecondsOfDay,
} from '../../../../utils/dateUtil';
import { successToast } from '../../../../utils/toast';
import {
  MASS_CANCEL_ALL_CALENDAR_TEMPLATES_VALUE,
  MassCancelFormValues,
} from './StaffMassCancelHooks';

export type UserRowActionCreator = (user: UserData) => React.ReactNode;

type Props = {
  formValues: MassCancelFormValues;
  onClose: () => void;
};

export const StaffMassCancelConfirmDialog = ({
  formValues,
  onClose,
}: Props) => {
  const { t } = useCommonTranslation();
  const { mutate: massCancelAppointment, isLoading: savingMassCancel } =
    useMutateMassCancelAppointment({
      onSuccess: () => {
        successToast(t('mass-cancel-success'));
        onClose();
      },
    });

  const dialogTitle = useMemo(() => {
    const { date, fromTime, toTime, fullDay } = formValues;
    return t(fullDay ? 'confirm-mass-cancel-full-day' : 'confirm-mass-cancel', {
      date: formatDateOnlySite(parseIsoDate(date)),
      fromTime,
      toTime,
    });
  }, [formValues, t]);

  const handleConfirm = useCallback(() => {
    const {
      date: dateString,
      fromTime: fromTimeString,
      toTime: toTimeString,
      clearApptTemplate,
      resourceTypeId,
      fullDay,
    } = formValues;
    const date = parseIsoDate(dateString);
    const fromSeconds = fullDay
      ? undefined
      : parseTimeInHHmmToSecondsOfDay(fromTimeString);
    const toSeconds = fullDay
      ? undefined
      : parseTimeInHHmmToSecondsOfDay(toTimeString);
    massCancelAppointment({
      date: formatDateOnlyISO(date),
      from_seconds: fromSeconds,
      to_seconds: toSeconds,
      clear_appt_template: clearApptTemplate,
      resource_type_id:
        resourceTypeId === MASS_CANCEL_ALL_CALENDAR_TEMPLATES_VALUE
          ? undefined
          : resourceTypeId,
    });
  }, [formValues, massCancelAppointment]);

  return (
    <ConfirmDialog
      title={dialogTitle}
      icon={<FaExclamationTriangle />}
      status="delete"
      actionsTopMargin
      handleClose={onClose}
      isLoading={savingMassCancel}
      actions={[
        <CommonButton
          key={'confirm_true'}
          variant="danger"
          onClick={handleConfirm}
        >
          {t('yes')}
        </CommonButton>,
        <CommonButton
          key={'confirm_false'}
          variant="secondary"
          onClick={onClose}
        >
          {t('no')}
        </CommonButton>,
      ]}
    />
  );
};
