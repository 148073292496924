import {
  DialogFormWrapper,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import TextArea from 'components/common/Forms/TextArea';
import { CardLoader } from 'components/common/Loading';
import { useWatch } from 'react-hook-form';
import { FaUsers } from 'react-icons/fa';

import { Card } from '../../../../components/common';
import {
  PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE,
  PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE,
} from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { UseCustomSettingsFormReturn } from './CustomSettingsHooks';
import CheckboxInput from '../../../../components/common/Forms/CheckboxInput';
import { ATTRIBUTE_SEPARATOR } from '../../../../configs/constants';

interface Props {
  isFetching: boolean;
  attributesForm: UseCustomSettingsFormReturn;
}

export const PatientPortalSettings = ({
  isFetching,
  attributesForm,
}: Props) => {
  const { t } = useCommonTranslation();

  const {
    formState: { errors },
    attributeRegister,
    control,
    register,
  } = attributesForm;
  const {
    attributes,
    customPatientBookingNoApptsInPeriodMessageChecked,
    customPatientBookingNoApptsInLastPeriodMessageChecked,
  } = useWatch({ control });

  return (
    <Card mb="var(--s4)">
      <DialogFormWrapper>
        <FormHeader>
          <FaUsers />
          <FormTitle>{t('patient-portal-settings')}</FormTitle>
        </FormHeader>
        <FormRow>
          <CheckboxInput
            onlyInRow
            label={t(
              'use-custom-patient-booking-no-appts-in-period-message-label',
            )}
            key={'customPatientBookingNoApptsInPeriodMessageChecked'}
            id={'customPatientBookingNoApptsInPeriodMessageChecked'}
            isToggle={true}
            {...register('customPatientBookingNoApptsInPeriodMessageChecked')}
            checked={customPatientBookingNoApptsInPeriodMessageChecked}
          />
        </FormRow>
        <FormRow>
          <TextArea
            label={t('text')}
            placeholder={
              customPatientBookingNoApptsInPeriodMessageChecked
                ? ''
                : t('pick-day-no-appts')
            }
            {...attributeRegister(
              PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE,
              (e) => e.target.value,
              {
                required: {
                  value: !!customPatientBookingNoApptsInPeriodMessageChecked,
                  message: t('must-not-empty') as string,
                },
                maxLength: 250,
              },
            )}
            value={
              customPatientBookingNoApptsInPeriodMessageChecked
                ? attributes?.find(
                    (atr) =>
                      atr.key === PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE,
                  )?.value_str
                : ''
            }
            disabled={!customPatientBookingNoApptsInPeriodMessageChecked}
            hasError={Boolean(
              errors[
                (ATTRIBUTE_SEPARATOR +
                  PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE) as 'attributes'
              ],
            )}
            helperText={t('max-chars', { count: 250 })}
          />
        </FormRow>
        <FormRow>
          <CheckboxInput
            onlyInRow
            label={t(
              'use-custom-patient-booking-no-appts-in-last-period-message-label',
            )}
            key={'customPatientBookingNoApptsInLastPeriodMessageChecked'}
            id={'customPatientBookingNoApptsInLastPeriodMessageChecked'}
            isToggle={true}
            {...register(
              'customPatientBookingNoApptsInLastPeriodMessageChecked',
            )}
            checked={customPatientBookingNoApptsInLastPeriodMessageChecked}
          />
        </FormRow>
        <FormRow>
          <TextArea
            label={t('text')}
            placeholder={
              customPatientBookingNoApptsInLastPeriodMessageChecked
                ? ''
                : t('pick-day-no-appts-last-period')
            }
            {...attributeRegister(
              PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE,
              (e) => e.target.value,
              {
                required: {
                  value:
                    !!customPatientBookingNoApptsInLastPeriodMessageChecked,
                  message: t('must-not-empty') as string,
                },
                maxLength: 250,
              },
            )}
            value={
              customPatientBookingNoApptsInLastPeriodMessageChecked
                ? attributes?.find(
                    (atr) =>
                      atr.key ===
                      PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE,
                  )?.value_str
                : ''
            }
            disabled={!customPatientBookingNoApptsInLastPeriodMessageChecked}
            hasError={Boolean(
              errors[
                (ATTRIBUTE_SEPARATOR +
                  PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE) as 'attributes'
              ],
            )}
            helperText={t('max-chars', { count: 250 })}
          />
        </FormRow>
        {isFetching && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
};
