import { CardHeader } from 'components/common';
import { CommonButton, CommonLink } from 'components/common/Forms/Button';
import {
  FormActionsStyle,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import { PageHelmet } from 'components/common/PageHelmet';
import {
  FormWrapper,
  SuccessMessage,
  SuccessWrapper,
} from 'components/views-components/un-auth/SignupForm/SignupForm.styled';
import { PatientLayout } from 'containers/PatientLayout';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaLock, FaUserCheck } from 'react-icons/fa';
import { sendPasswordResetEmail } from 'services/firebase';
import { toRem } from 'utils/helpers';
import { useEmailValidation } from '../../hooks/validation/useEmailValidation';
import { LABEL_BEFORE } from '../../styles/fontsStyleUtils';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { upgradeUser } from '../../services/userProfile';
import { errorToast } from '../../utils/toast';

export const ResetPassword = () => {
  const { t } = useCommonTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<{
    email: string;
  }>();

  const submitHandler = async (data: { email: string }) => {
    setIsLoading(true);
    try {
      const response = await upgradeUser(data);
      if (response.data.status === 'USER_UPGRADE_STATUS_USER_DOES_NOT_EXIST') {
        const message = t('reset-password-email-not-found', {
          email: data.email,
        });
        setError('email', {
          message,
        });
        errorToast(message);
      } else {
        await sendPasswordResetEmail(data.email);
        setIsSuccess(true);
      }
    } catch (err) {
      errorToast(err);
    } finally {
      setIsLoading(false);
    }
  };

  const emailValidation = useEmailValidation();

  return (
    <PatientLayout graphics="unauth">
      <PageHelmet title={t('reset-password')} />
      {!isSuccess ? (
        <FormWrapper as="form" onSubmit={handleSubmit(submitHandler)}>
          <CardHeader>
            <FaLock />
            <FormTitle>{t('reset-password')}</FormTitle>
          </CardHeader>
          <FormRow>
            <Input
              label={t('email')}
              placeholder={t('email')}
              disabled={isLoading}
              labelPosition={LABEL_BEFORE}
              {...register('email', emailValidation)}
              helperText={errors.email?.message}
              hasError={Boolean(errors?.email)}
            />
          </FormRow>
          <FormActionsStyle>
            <CommonButton
              variant="primary"
              type="submit"
              style={{ minWidth: `${toRem(160)}rem` }}
              disabled={isLoading}
            >
              {t('reset-password')}
            </CommonButton>
            <CommonLink
              style={{ minWidth: `${toRem(160)}rem` }}
              to="/login"
              variant="secondary"
            >
              {t('back-to-login')}
            </CommonLink>
          </FormActionsStyle>
        </FormWrapper>
      ) : (
        <FormWrapper as="div">
          <SuccessWrapper>
            <FaUserCheck />
            <SuccessMessage>{t('reset-password-success')}</SuccessMessage>
            <SuccessMessage
              style={{
                textAlign: 'center',
              }}
            >
              {t('reset-password-success-subtitle')}
            </SuccessMessage>
            <CommonLink variant="primary" to="/login">
              {t('back-to-login')}
            </CommonLink>
          </SuccessWrapper>
        </FormWrapper>
      )}
    </PatientLayout>
  );
};
