import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import {
  PatientBookingStageTitle,
  PatientBookingStageTitle2,
} from '../PatientBooking.styled';
import { formatDateOnlySite } from '../../../../utils/dateUtil';
import {
  PatientBookingControl,
  PatientBookingControlProps,
} from './PatientBookingControl';
import { WithSite } from '../../../../types/sites';
import { useSpecificSiteStringAttributes } from '../../../../hooks/useSpecificSiteAttribute';
import { PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE } from '../../../../configs/siteAndTrustAttributes';

export const PatientBookingPickDayNoAppts = ({
  site,
  searchPeriod: { toDate, fromDate },
  ...rest
}: {
  searchPeriod: {
    fromDate: Date;
    toDate: Date;
  };
} & PatientBookingControlProps &
  WithSite) => {
  const { t } = useCommonTranslation();
  const [noApptMessage] = useSpecificSiteStringAttributes(
    site,
    PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE,
  );

  return (
    <>
      <PatientBookingStageTitle>
        {noApptMessage || t('pick-day-no-appts')}
      </PatientBookingStageTitle>
      <PatientBookingStageTitle2>
        {t('pick-day-searching-range', {
          from: formatDateOnlySite(fromDate),
          to: formatDateOnlySite(toDate),
        })}
      </PatientBookingStageTitle2>
      <PatientBookingControl {...rest} />
    </>
  );
};
