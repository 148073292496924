import { useCallback, useMemo } from 'react';
import { SignupFormValues } from '../../../../types/users';
import { UseFormTrigger } from 'react-hook-form/dist/types/form';
import { useSteps } from '../../../../hooks/util/useSteps';
import { errorToast } from '../../../../utils/toast';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { UserUpgradeResponseStatus } from '../../../../types/authentication';

interface Props {
  userInFirebaseNotSangixDb?: boolean;
  trigger: UseFormTrigger<SignupFormValues>;
  finishRegistration: (values: SignupFormValues) => Promise<void>;
  onAbandoningSignUp: () => void;
  registeringForSpecificSite?: boolean;
  verifyEmailOnBackend: (email: string) => Promise<UserUpgradeResponseStatus>;
}

type RegisterSteps =
  | 'EMAIL'
  | 'SELECT_HOSPITAL'
  | 'CONTACT_DETAILS'
  | 'PATIENT_INFO';

export const STEPS: { [id in RegisterSteps]: RegisterSteps } = {
  EMAIL: 'EMAIL',
  SELECT_HOSPITAL: 'SELECT_HOSPITAL',
  CONTACT_DETAILS: 'CONTACT_DETAILS',
  PATIENT_INFO: 'PATIENT_INFO',
};

export const useSignupFormSteps = ({
  userInFirebaseNotSangixDb,
  registeringForSpecificSite,
  trigger,
  finishRegistration,
  onAbandoningSignUp,
  verifyEmailOnBackend,
}: Props) => {
  const { t } = useCommonTranslation();

  const skippedSteps = useMemo(() => {
    const skippedSteps: RegisterSteps[] = [];
    if (userInFirebaseNotSangixDb) {
      skippedSteps.push(STEPS.EMAIL);
    }
    if (registeringForSpecificSite) {
      skippedSteps.push(STEPS.SELECT_HOSPITAL);
    }
    return skippedSteps;
  }, [userInFirebaseNotSangixDb, registeringForSpecificSite]);

  const {
    currentStep,
    isCurrentStepFirstStep,
    nextStep,
    previousStep,
    setCurrentStep,
    isCurrentStepFinalStep,
  } = useSteps<RegisterSteps>({
    skippedSteps,
    stepNavigation: {
      ENTRY: STEPS.EMAIL,
      [STEPS.EMAIL]: STEPS.SELECT_HOSPITAL,
      [STEPS.SELECT_HOSPITAL]: STEPS.CONTACT_DETAILS,
      [STEPS.CONTACT_DETAILS]: STEPS.PATIENT_INFO,
      [STEPS.PATIENT_INFO]: 'FINAL',
    },
  });

  const onBackStepClicked = useCallback(async () => {
    if (isCurrentStepFirstStep) {
      onAbandoningSignUp();
    } else {
      setCurrentStep(previousStep);
    }
  }, [
    isCurrentStepFirstStep,
    onAbandoningSignUp,
    previousStep,
    setCurrentStep,
  ]);

  const onNextTrigger = useCallback(
    async (values: SignupFormValues) => {
      if (isCurrentStepFinalStep) {
        return await finishRegistration(values);
      }
      switch (currentStep) {
        case STEPS.EMAIL:
          // Here we are not dealing with firebase user, as he skips this step
          const isEmailValid = await trigger(['email']);
          const isPasswordsValid = await trigger([
            'password',
            'confirmPassword',
          ]);
          try {
            const emailBackendCheckResult = await verifyEmailOnBackend(
              values.email,
            );
            if (
              emailBackendCheckResult === 'USER_UPGRADE_STATUS_USER_EXIST' ||
              emailBackendCheckResult ===
                'USER_UPGRADE_STATUS_USER_EXIST_UPDATED'
            ) {
              errorToast(t('user-already-exists-reset-or-login'));
            }
            if (
              isEmailValid &&
              isPasswordsValid &&
              emailBackendCheckResult ===
                'USER_UPGRADE_STATUS_USER_DOES_NOT_EXIST'
            ) {
              setCurrentStep(nextStep);
            }
          } catch (error) {
            // No need to handle error, toast will be shown
          }
          break;
        case STEPS.SELECT_HOSPITAL:
          // Triggered once active site/trust is loaded
          setCurrentStep(nextStep);
          break;
        case STEPS.CONTACT_DETAILS:
          const isContactDetailsValid = await trigger([
            'firstname',
            'lastname',
            'phone',
          ]);
          if (isContactDetailsValid) {
            setCurrentStep(nextStep);
          }
          break;
        default:
          throw new Error('Invalid step');
      }
    },
    [
      isCurrentStepFinalStep,
      currentStep,
      finishRegistration,
      trigger,
      setCurrentStep,
      nextStep,
      t,
      verifyEmailOnBackend,
    ],
  );
  return {
    step: currentStep,
    onNextClicked: onNextTrigger,
    setStep: setCurrentStep,
    onBackStepClicked,
  };
};
