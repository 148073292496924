import { useCallback, useMemo, useState } from 'react';

type EntryAndFinal = 'ENTRY' | 'FINAL';

const ENTRY_STEP: EntryAndFinal = 'ENTRY';
const FINAL_STEP: EntryAndFinal = 'FINAL';

export const useSteps = <T extends string>({
  skippedSteps = [],
  stepNavigation,
}: {
  skippedSteps: T[];
  stepNavigation: Partial<{
    [id in T | EntryAndFinal]: T | EntryAndFinal;
  }>;
}) => {
  const getNextStep = useCallback(
    (step: EntryAndFinal | T) => {
      let nextStep = stepNavigation[step];
      while (
        nextStep &&
        nextStep !== FINAL_STEP &&
        nextStep !== ENTRY_STEP &&
        skippedSteps.includes(nextStep as T)
      ) {
        nextStep = stepNavigation[nextStep as T | EntryAndFinal];
      }
      if (!nextStep) {
        throw new Error(`No next step found for ${step}`);
      }
      return nextStep as T;
    },
    [skippedSteps, stepNavigation],
  );

  const [currentStepInternal, setCurrentStepInternal] = useState<T>(
    getNextStep(ENTRY_STEP),
  );

  const setCurrentStep = useCallback((step: EntryAndFinal | T | undefined) => {
    if (step === FINAL_STEP) {
      throw new Error(
        'Cannot set FINAL step, it is a placeholder where the steps should end',
      );
    } else if (step === ENTRY_STEP) {
      throw new Error(
        'Cannot set ENTRY step, it is a placeholder where the steps should start',
      );
    } else if (!step) {
      throw new Error('Cannot set step to undefined');
    } else {
      setCurrentStepInternal(step);
    }
  }, []);

  const previousStep = useMemo(() => {
    const getPreviousStep = (step: EntryAndFinal | T) =>
      Object.keys(stepNavigation).find(
        (key) => stepNavigation[key as T | EntryAndFinal] === step,
      ) as EntryAndFinal | T | undefined;

    let previousStepInner = getPreviousStep(currentStepInternal);
    while (previousStepInner && skippedSteps.includes(previousStepInner as T)) {
      previousStepInner = getPreviousStep(previousStepInner);
    }
    return previousStepInner;
  }, [skippedSteps, currentStepInternal, stepNavigation]);

  const nextStep = useMemo(
    () => getNextStep(currentStepInternal),
    [currentStepInternal, getNextStep],
  );

  return {
    currentStep: currentStepInternal,
    setCurrentStep,
    previousStep,
    nextStep,
    isCurrentStepFirstStep: previousStep === ENTRY_STEP,
    isCurrentStepFinalStep: nextStep === FINAL_STEP,
  };
};
