import React, { useEffect, useMemo, useState } from 'react';
import { Popup } from '../../../components/Popup/Popup';
import { useAuth } from '../../../context/AuthContext';
import { useUpdateAuthUser } from '../../../hooks/user/useUpdateAuthUser';
import { CommonButton } from '../../../components/common/Forms/Button';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { Card, CardHeader } from '../../../components/common';
import { CardLoader } from '../../../components/common/Loading';
import {
  PopupDesc,
  PopupIconWrapper,
  PopupTitle,
} from '../../../components/Popup/ConfirmDialog/ConfirmDialog.styled';
import { FaUserCog } from 'react-icons/fa';
import { FormActionsStyle } from '../../../components/common/Forms/Forms.styled';
import { checkErrorCode } from '../../../utils/errors';
import { ErrorCode } from '../../../utils/errorCodes';
import { errorToast } from '../../../utils/toast';

/**
 * This code is able to check and deal with authentication problems between sangix DB and firebase.
 * @see https://sangix.atlassian.net/wiki/spaces/SAN/pages/361824257
 * Item #7 Login and Login/Register with Google
 */
export const SangixDbToFirebaseEmailMismatchHandler = () => {
  const { sangixUser, firebaseUser, logout } = useAuth();
  const { updateAuthUser } = useUpdateAuthUser();
  const { t } = useCommonTranslation();

  const [infoDialogState, setInfoDialogState] = useState<
    'CLOSED' | 'UPDATING_EMAIL' | 'UPDATED_SUCCESSFULLY'
  >('CLOSED');

  const hasUnresolvableMismatch = useMemo(
    () => sangixUser && firebaseUser && sangixUser.email !== firebaseUser.email,
    [sangixUser, firebaseUser],
  );

  useEffect(() => {
    const updateEmail = async () => {
      setInfoDialogState('UPDATING_EMAIL');
      try {
        await updateAuthUser({
          ...sangixUser!!,
          email: firebaseUser.email,
        });
        setInfoDialogState('UPDATED_SUCCESSFULLY');
      } catch (e) {
        if (checkErrorCode(e, ErrorCode.ACCOUNT_ID_FIREBASE_ID_MISMATCH)) {
          // This is a very unlikely special case. See https://sangix.atlassian.net/wiki/spaces/SAN/pages/361824257 #10
          errorToast(t('error-codes.FE0001'));
        } else {
          errorToast(e);
        }
        setInfoDialogState('CLOSED');
        await logout();
      }
    };
    if (hasUnresolvableMismatch) {
      updateEmail();
    }
  }, [
    hasUnresolvableMismatch,
    sangixUser,
    firebaseUser,
    logout,
    updateAuthUser,
    t,
  ]);

  return (
    <>
      <Popup
        closeOnDocumentClick={false}
        closeOnEscape={false}
        open={infoDialogState !== 'CLOSED'}
        size="small"
      >
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CardHeader>
            <PopupIconWrapper inlineIcon>
              <FaUserCog />
            </PopupIconWrapper>
            <PopupTitle compact>
              {infoDialogState === 'UPDATING_EMAIL'
                ? t('sangix-db-email-update-title-updating')
                : t('sangix-db-email-update-title-updated')}
            </PopupTitle>

            {infoDialogState === 'UPDATING_EMAIL' && <CardLoader />}
            {infoDialogState === 'UPDATED_SUCCESSFULLY' && (
              <PopupDesc
                style={{
                  marginTop: 'var(--s3)',
                }}
              >
                {t('sangix-db-email-updated-to-firebase', {
                  email: firebaseUser?.email,
                })}
              </PopupDesc>
            )}
          </CardHeader>
          <FormActionsStyle
            align={'center'}
            style={{
              marginTop: 'auto',
            }}
          >
            <CommonButton
              variant={'primary'}
              onClick={() => setInfoDialogState('CLOSED')}
            >
              {t('ok')}
            </CommonButton>
          </FormActionsStyle>
        </Card>
      </Popup>
    </>
  );
};
