import React, { ReactElement, useEffect, useState } from 'react';
import {
  FormDesc,
  FormTitle,
  SignUpHospitalList,
  SignUpHospitalName,
  SignUpHospitalShortInfo,
  SignUpHospitalWrapper,
} from './SignupForm.styled';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useDebounce } from 'react-use';
import { useQuerySitesByName } from '../../../../query/sites';
import { CommonButton } from '../../../common/Forms/Button';
import { CommonInput } from '../../../common/Forms/Input';
import { getHookFormTyped } from '../../../../utils/helpers';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

interface SignupStepHospitalSelectForm {
  email?: string;
  active_site_id?: string;
}

interface Props<T extends SignupStepHospitalSelectForm> {
  setValue: UseFormSetValue<T>;
}

export const SignupStepHospitalSelect = <
  T extends SignupStepHospitalSelectForm,
>({
  ...rest
}: Props<T>): ReactElement => {
  const { t } = useCommonTranslation();

  const [searchValue, setSearchValue] = useState('');

  const { setValue } = getHookFormTyped<SignupStepHospitalSelectForm, T>(rest);

  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [, cancel] = useDebounce(
    () => {
      setDebouncedSearchValue(searchValue);
    },
    500,
    [searchValue, setDebouncedSearchValue],
  );

  useEffect(() => {
    return () => {
      cancel();
    };
  }, [cancel]);

  const { data: suggestions } = useQuerySitesByName(debouncedSearchValue);

  return (
    <>
      <FormTitle>{t('select-hospital')}</FormTitle>
      <FormDesc
        style={{
          fontSize: 'var(--h4)',
        }}
      >
        {t('sites-hospital-select-title')}
      </FormDesc>
      <FormDesc>{t('sites-hospital-select-subtitle')}</FormDesc>
      <CommonInput
        label={t('hospital')}
        labelPosition={'labelBefore'}
        required
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        value={searchValue}
        name={'hospital_search'}
        containerStyle={{ marginBottom: 0 }}
        onKeyPress={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        autoFocus
      />
      <SignUpHospitalList>
        {suggestions?.data.map((siteInfo, _) => (
          <SignUpHospitalWrapper key={`hospital_select_${siteInfo.id}`}>
            <div>
              <SignUpHospitalName>{siteInfo.name}</SignUpHospitalName>
              <SignUpHospitalShortInfo>
                {siteInfo.sign_up_short_info}
              </SignUpHospitalShortInfo>
            </div>
            <CommonButton
              style={{
                marginLeft: 'auto',
              }}
              size={'standard'}
              variant={'primary'}
              onClick={() => {
                setValue('active_site_id', siteInfo.id);
              }}
              data-testid={`${siteInfo.name}_select_button`}
            >
              {t('select')}
            </CommonButton>
          </SignUpHospitalWrapper>
        ))}
      </SignUpHospitalList>
    </>
  );
};
