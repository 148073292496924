import { FormRow } from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import React, { ReactElement } from 'react';
import { FormTitle } from './SignupForm.styled';
import { Control, UseFormRegister } from 'react-hook-form/dist/types/form';
import { getHookFormTyped } from '../../../../utils/helpers';
import { Controller, FieldErrors } from 'react-hook-form';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useUserValidationRules } from '../../../../hooks/patient/useUserValidationRules';
import { TrustData } from '../../../../types/trusts';
import { SiteData } from '../../../../types/sites';
import { InputPhoneNumber } from '../../../common/Forms/InputPhoneNumber';

type SignupStepContactDetailsForm = {
  firstname: string;
  lastname: string;
  phone?: string;
};

interface Props<T extends SignupStepContactDetailsForm> {
  errors: FieldErrors<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  trust: TrustData;
  site: SiteData;
}

export const SignupStepContactDetails = <
  T extends SignupStepContactDetailsForm,
>({
  trust,
  site,
  ...rest
}: Props<T>): ReactElement => {
  const { t } = useCommonTranslation();
  const { register, errors, control } = getHookFormTyped<
    SignupStepContactDetailsForm,
    T
  >(rest);
  const { surnameRules, firstnameRules, phoneRules } = useUserValidationRules({
    trusts: [trust],
    emailRequired: true,
  });

  return (
    <>
      <FormTitle>
        {t('lets-get-started') + ' (' + site.short_name + ')'}
      </FormTitle>
      <FormRow>
        <Input
          label={t('first-name')}
          placeholder={t('first-name')}
          {...register('firstname', firstnameRules)}
          helperText={errors.firstname?.message}
          hasError={Boolean(errors?.firstname)}
          required
          autoFocus
        />
        <Input
          label={t('surname')}
          placeholder={t('surname')}
          {...register('lastname', surnameRules)}
          helperText={errors.lastname?.message}
          hasError={Boolean(errors?.lastname)}
          required
        />
      </FormRow>
      <FormRow>
        <Controller
          name="phone"
          control={control}
          rules={phoneRules}
          render={({ field }) => (
            <InputPhoneNumber
              label={t('phone-number')}
              placeholder={t('phone-number')}
              {...field}
              required={!!phoneRules.required}
              helperText={errors.phone?.message}
              hasError={Boolean(errors?.phone)}
            />
          )}
        />
      </FormRow>
    </>
  );
};
