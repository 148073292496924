import { GlobalAttribute } from 'types/common';

import {
  PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE,
  PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE,
} from '../../../../configs/siteAndTrustAttributes';
import {
  conditionalStringSiteAttribute,
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';
import { UseCustomSettingsFormHooks } from './CustomSettingsHooks';
import { useMemo } from 'react';

const keys = siteAttributeKeysSubset([
  PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE,
  PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE,
]);

const restAttributes: GlobalAttribute[] = keys.map((key) => ({ key }));

export const usePatientPortalSettingsFormHooks: UseCustomSettingsFormHooks = ({
  site,
}) => {
  const defaultValues = useMemo(
    () => {
      return {
        customPatientBookingNoApptsInPeriodMessageChecked: Boolean(
          site.attributes?.find(
            (atr) => atr.key === PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE,
          )?.value_str,
        ),
        customPatientBookingNoApptsInLastPeriodMessageChecked: Boolean(
          site.attributes?.find(
            (atr) =>
              atr.key === PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE,
          )?.value_str,
        ),
      };
    },
    // shallow comparison is fine here as we are using it for default form value only
    [site.attributes],
  );

  return {
    restAttributes,
    defaultValues,
    beforeSubmit: (updatedSite, formValues) => {
      const {
        customPatientBookingNoApptsInPeriodMessageChecked,
        customPatientBookingNoApptsInLastPeriodMessageChecked,
      } = formValues;
      return {
        ...updatedSite,
        attributes: updateSiteAttributes(
          updatedSite.attributes,
          formValues.attributes,
          keys,
          (key, siteValue, formValue) => {
            const updatedValue = formValue || siteValue;
            switch (key) {
              case PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE:
                return conditionalStringSiteAttribute({
                  key,
                  value: updatedValue,
                  condition: customPatientBookingNoApptsInPeriodMessageChecked,
                });
              case PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE:
                return conditionalStringSiteAttribute({
                  key,
                  value: updatedValue,
                  condition:
                    customPatientBookingNoApptsInLastPeriodMessageChecked,
                });
              default:
                return updatedValue;
            }
          },
        ),
      };
    },
  };
};
