export const SUPPORT_EMAILS_ATTRIBUTE = 'supportEmails';

export const REPORT_EMAILS_ATTRIBUTE = 'reportEmails';
export const SHOW_OVERFLOW_CALENDAR = 'showoverflowcalendar';
export const MAX_APPT_WEEK = 'maxApptWeek';
export const MAX_APPT_ALL = 'maxApptAll';
export const CHECKIN_DUPLICATE_TEXT = 'checkinDuplicateText';
export const EMAIL_HOSPITAL_URL = 'emailHospitalUrl';
export const STAFF_OPEN_HOUR_IN_SECONDS = 'openHour';
export const STAFF_CLOSE_HOUR_IN_SECONDS = 'closeHour';
export const CHECK_IN_SEARCH_EARLY = 'checkinSearchEarly';
export const CHECK_IN_SEARCH_LATE = 'checkinSearchLate';
export const KIOSK_AND_SELF_CHECK_IN_EARLY = 'checkinEarly';
export const KIOSK_AND_SELF_CHECK_IN_LATE = 'checkinLate';
export const QUEUE_TO_DNA = 'queueToDna';
export const KIOSK_IP_RANGE = 'kioskIPRange';
export const PATIENTS_LATEST_BOOKING_BEFORE_IN_DAYS = 'onlineBooking';
export const PATIENTS_EARLIEST_BOOKING_AFTER_IN_DAYS = 'onlineEarliest';
export const STAFF_LATEST_BOOKING_BEFORE_IN_DAYS = 'staffBooking';
export const STAFF_EARLIEST_BOOKING_AFTER_IN_DAYS = 'staffEarliest';
export const SITE_WEEK_DAYS = 'weekDays';
export const SHOW_MIA_BUTTON_IN_APPOINTMENT_PROCESSING =
  'showMiaInAppointmentProcessing';
export const SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE = 'showBirthDateInCubicleQueue';
export const MANDATORY_SOURCE = 'mandatorysource';
export const SHOW_APPOINTMENT_REFERENCE_NUMBER =
  'showAppointmentReferenceNumber';
export const MIN_AGE_ATTRIBUTE = 'minAge';
export const MAX_AGE_ATTRIBUTE = 'maxAge';

export const UNDER_MIN_AGE_MESSAGE = 'underMinAgeMessage';
export const OVER_MAX_AGE_MESSAGE = 'overMaxAgeMessage';

export const APPT_FLAGS_SELECTED = 'apptFlagsSelected';
export const PRE_CHECK_IN_QUESTION = 'preCheckinQuestion';
export const PRE_CHECK_IN_QUESTION_NO = 'preCheckinQuestionNo';
export const CHECK_IN_EARLY_TEXT = 'checkinEarlyText';
export const CHECK_IN_LATE_TEXT = 'checkinLateText';
export const CHECK_IN_SUCCESS_TEXT = 'checkinSuccessText';

export const PRE_BOOK_QUESTION = 'preBookQuestion';
export const PRE_BOOK_CORRECT_ANSWER = 'preBookCorrectAnswer';
export const PRE_BOOK_INCORRECT_ANSWER = 'preBookIncorrectAnswer';
export const PRE_BOOK_INCORRECT_ANSWER_MESSAGE =
  'preBookIncorrectAnswerMessage';

export const PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE =
  'patientBookingNoApptsInPeriodMessage';
export const PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE =
  'patientBookingNoApptsInLastPeriodMessage';

export const LCD_RUNNING_TEXT = 'lcdrunningtext';
export const FORMS = 'forms';
export const TUBES = 'tubes';
export const SHOW_WAITING_LIST = 'showwaitinglist';
export const LCD_SHORT_NAMES = 'lcdshortnames';
export const DISABLE_SELF_CHECK_IN = 'disableSelfCheckin';

export const SHOW_PATIENT_SUPPORT_FORM = 'showPatientSupportForm';

export const SERVER_EMAIL_ALLOW_CREATE = 'serverEmailAllowCreate';
export const SERVER_EMAIL_ALLOW_CONFIRMATION = 'serverEmailAllowConfirmation';
export const SERVER_EMAIL_ALLOW_CANCEL = 'serverEmailAllowCancel';
export const SERVER_EMAIL_ALLOW_REMINDER = 'serverEmailAllowReminder';
export const SERVER_EMAIL_ALLOW_APPT = 'serverEmailAllowAppt';
export const SERVER_EMAIL_ALLOW_EMAIL_UPDATE = 'serverEmailAllowEmailUpdate';
export const SERVER_EMAIL_ALLOW_DAILY_REPORT = 'serverEmailAllowDailyReport';
export const SERVER_EMAIL_ALLOW_WAITING_LIST = 'serverEmailAllowWaitingList';
export const SERVER_EMAIL_ALLOW_SUPPORT_FORM = 'serverEmailAllowSupportForm';

export const SERVER_EMAIL_SUBJ_CREATE = 'serverEmailSubjCreate';
export const SERVER_EMAIL_SUBJ_CONFIRMATION = 'serverEmailSubjConfirmation';
export const SERVER_EMAIL_SUBJ_CANCEL = 'serverEmailSubjCancel';
export const SERVER_EMAIL_SUBJ_APPT = 'serverEmailSubjAppt';
export const SERVER_EMAIL_SUBJ_REMINDER = 'serverEmailSubjReminder';
export const SERVER_EMAIL_SUBJ_DAILY_REPORT = 'serverEmailSubjDailyReport';
export const SERVER_EMAIL_SUBJ_WAITING_LIST = 'serverEmailSubjWaitingList';
export const SERVER_EMAIL_SUBJ_SUPPORT_FORM = 'serverEmailSubjSupportForm';

export const SERVER_EMAIL_SENDER = 'serverEmailSender';
export const SERVER_FORCED_EMAIL_RECEIVER = 'serverForcedEmailReceiver';

export const COLOR_PALETTE = 'colorPalette';

export const VARIANT = 'variant';
export const ENABLE_BACKGROUND_GRAPHICS = 'enableBackgroundGraphics';

export const HOLIDAY_IMPORT_DATE = 'holidayImportDate';
export const IMPORT_HOLIDAYS = 'importHolidays';

export const SITE_ATTRIBUTE_CALLING_SCREEN_CUBICLE_MESSAGE =
  'callingScreenCubicleMessage';
export const SITE_ATTRIBUTE_NOTE = 'note';
export const SITE_ATTRIBUTE_ALLOW_GP_SURGERY_ROLE = 'allowGPSurgeryRole';
export const SITE_ATTRIBUTE_SALUTARE_INTEGRATION_BASE_URL =
  'salutareIntegrationBaseUrl';

export const DefaultSiteAndTrustAttributes = [
  UNDER_MIN_AGE_MESSAGE,
  OVER_MAX_AGE_MESSAGE,
  MIN_AGE_ATTRIBUTE,
  MAX_AGE_ATTRIBUTE,
  SUPPORT_EMAILS_ATTRIBUTE,
  SITE_ATTRIBUTE_ALLOW_GP_SURGERY_ROLE,
  'unknownGPSurgery',
  'timezone',
  SITE_WEEK_DAYS,
  STAFF_OPEN_HOUR_IN_SECONDS,
  STAFF_CLOSE_HOUR_IN_SECONDS,
  STAFF_EARLIEST_BOOKING_AFTER_IN_DAYS,
  STAFF_LATEST_BOOKING_BEFORE_IN_DAYS,
  PATIENTS_EARLIEST_BOOKING_AFTER_IN_DAYS,
  PATIENTS_LATEST_BOOKING_BEFORE_IN_DAYS,
  QUEUE_TO_DNA,
  'dnaBanditDnaLimit',
  'dnaBanditPeriodMonths',
  'lcdbookingtime',
  LCD_RUNNING_TEXT,
  'mandatorypostcode',
  MANDATORY_SOURCE,
  SHOW_WAITING_LIST,
  LCD_SHORT_NAMES,
  'lcdshowqueued',
  KIOSK_AND_SELF_CHECK_IN_EARLY,
  CHECK_IN_EARLY_TEXT,
  KIOSK_AND_SELF_CHECK_IN_LATE,
  CHECK_IN_LATE_TEXT,
  CHECK_IN_SUCCESS_TEXT,
  CHECKIN_DUPLICATE_TEXT,
  MAX_APPT_WEEK,
  MAX_APPT_ALL,
  COLOR_PALETTE,
  EMAIL_HOSPITAL_URL,
  SERVER_EMAIL_SENDER,
  SERVER_FORCED_EMAIL_RECEIVER,
  SERVER_EMAIL_SUBJ_CONFIRMATION,
  SERVER_EMAIL_SUBJ_CANCEL,
  SERVER_EMAIL_SUBJ_APPT,
  SERVER_EMAIL_SUBJ_REMINDER,
  'serverEmailSubjDna',
  SERVER_EMAIL_SUBJ_CREATE,
  SERVER_EMAIL_SUBJ_DAILY_REPORT,
  SERVER_EMAIL_SUBJ_WAITING_LIST,
  SERVER_EMAIL_SUBJ_SUPPORT_FORM,
  'serverEmailSubjEmailUpdate',
  SERVER_EMAIL_ALLOW_CONFIRMATION,
  SERVER_EMAIL_ALLOW_CANCEL,
  SERVER_EMAIL_ALLOW_REMINDER,
  SERVER_EMAIL_ALLOW_APPT,
  'serverEmailAllowDna',
  SERVER_EMAIL_ALLOW_CREATE,
  SERVER_EMAIL_ALLOW_DAILY_REPORT,
  SERVER_EMAIL_ALLOW_WAITING_LIST,
  SERVER_EMAIL_ALLOW_SUPPORT_FORM,
  SERVER_EMAIL_ALLOW_EMAIL_UPDATE,
  ENABLE_BACKGROUND_GRAPHICS,
  FORMS,
  TUBES,
  'serverSupportHidden',
  'serverPublicSupportHidden',
  HOLIDAY_IMPORT_DATE,
  DISABLE_SELF_CHECK_IN,
  PRE_CHECK_IN_QUESTION,
  PRE_CHECK_IN_QUESTION_NO,
  SHOW_OVERFLOW_CALENDAR,
  CHECK_IN_SEARCH_EARLY,
  CHECK_IN_SEARCH_LATE,
  KIOSK_IP_RANGE,
  SHOW_MIA_BUTTON_IN_APPOINTMENT_PROCESSING,
  SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE,
  SHOW_APPOINTMENT_REFERENCE_NUMBER,
  REPORT_EMAILS_ATTRIBUTE,
  APPT_FLAGS_SELECTED,
  SHOW_PATIENT_SUPPORT_FORM,
  PRE_BOOK_QUESTION,
  PRE_BOOK_CORRECT_ANSWER,
  PRE_BOOK_INCORRECT_ANSWER,
  PRE_BOOK_INCORRECT_ANSWER_MESSAGE,
  IMPORT_HOLIDAYS,
  VARIANT,
  SITE_ATTRIBUTE_CALLING_SCREEN_CUBICLE_MESSAGE,
  SITE_ATTRIBUTE_NOTE,
  SITE_ATTRIBUTE_SALUTARE_INTEGRATION_BASE_URL,
  PATIENT_BOOKING_NO_APPTS_IN_PERIOD_MESSAGE,
  PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE,
] as const;

export type DefaultSiteOrTrustAttribute =
  (typeof DefaultSiteAndTrustAttributes)[number];
