import { useMemo } from 'react';
import { GlobalAttribute } from 'types/common';

import {
  KIOSK_IP_RANGE,
  PRE_CHECK_IN_QUESTION,
  PRE_CHECK_IN_QUESTION_NO,
  SITE_ATTRIBUTE_CALLING_SCREEN_CUBICLE_MESSAGE,
} from '../../../../configs/siteAndTrustAttributes';
import {
  conditionalStringSiteAttribute,
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';
import { UseCheckInPreferencesFormHooks } from './CheckInPreferencesHooks';

const keys = siteAttributeKeysSubset([
  PRE_CHECK_IN_QUESTION,
  PRE_CHECK_IN_QUESTION_NO,
  KIOSK_IP_RANGE,
  SITE_ATTRIBUTE_CALLING_SCREEN_CUBICLE_MESSAGE,
]);

const restAttributes: GlobalAttribute[] = keys.map((key) => ({ key }));

export const useCheckInRightSideFormHooks: UseCheckInPreferencesFormHooks = ({
  site,
}) => {
  const defaultValues = useMemo(
    () => {
      return {
        enablePreCheckInQuestion: Boolean(
          site.attributes?.find((atr) => atr.key === PRE_CHECK_IN_QUESTION)
            ?.value_str,
        ),
      };
    },
    // shallow comparison is fine here as we are using it for default form value only
    [site.attributes],
  );

  return {
    restAttributes,
    defaultValues,
    beforeSubmit: (updatedSite, formValues) => {
      const { enablePreCheckInQuestion } = formValues;
      return {
        ...updatedSite,
        attributes: updateSiteAttributes(
          updatedSite.attributes,
          formValues.attributes,
          keys,
          (key, siteValue, formValue) => {
            const updatedValue = formValue || siteValue;
            switch (key) {
              case PRE_CHECK_IN_QUESTION:
              case PRE_CHECK_IN_QUESTION_NO:
                return conditionalStringSiteAttribute({
                  key,
                  value: updatedValue,
                  condition: enablePreCheckInQuestion,
                });
              default:
                return updatedValue;
            }
          },
        ),
      };
    },
  };
};
