import React, { ReactElement, useEffect } from 'react';
import { FormTitle } from './SignupForm.styled';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { FormRow } from '../../../common/Forms/Forms.styled';
import Input, { CommonInput } from '../../../common/Forms/Input';
import { useUserValidationRules } from '../../../../hooks/patient/useUserValidationRules';
import { getHookFormTyped } from '../../../../utils/helpers';
import { FieldErrors, UseFormWatch } from 'react-hook-form';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormTrigger,
} from 'react-hook-form/dist/types/form';

interface SignupStepEmailSelectForm {
  email?: string;
  password: string;
  confirmPassword: string;
}

interface Props<T extends SignupStepEmailSelectForm> {
  errors: FieldErrors<T>;
  register: UseFormRegister<T>;
  watch: UseFormWatch<T>;
  trigger: UseFormTrigger<T>;
  getValues: UseFormGetValues<T>;
}

/**
 * Skipped if email set via social login
 */
export const SignupStepEmailPwd = <T extends SignupStepEmailSelectForm>({
  ...rest
}: Props<T>): ReactElement => {
  const { t } = useCommonTranslation();

  const { register, errors, getValues, trigger, watch } = getHookFormTyped<
    SignupStepEmailSelectForm,
    T
  >(rest);

  const password = watch('password');
  useEffect(() => {
    if (password && getValues('confirmPassword')) {
      trigger('confirmPassword');
    }
  }, [password, getValues, trigger]);

  const { emailRules } = useUserValidationRules({
    emailRequired: true,
  });

  return (
    <>
      <FormTitle>{t('credentials')}</FormTitle>
      <FormRow>
        <CommonInput
          label={t('email')}
          placeholder={t('email')}
          {...register('email', emailRules)}
          helperText={errors.email?.message}
          hasError={Boolean(errors?.email)}
          required
        />
      </FormRow>
      <FormRow>
        <Input
          autoComplete={'new-password'}
          label={t('password')}
          placeholder={t('password')}
          type="password"
          {...register('password', {
            required: t('must-not-empty') as string,
            pattern: {
              value: /\d/,
              message: t('password-pattern-validation') as string,
            },
            minLength: {
              value: 8,
              message: t('password-minlength-validation') as string,
            },
          })}
          helperText={errors.password?.message}
          hasError={Boolean(errors?.password)}
          required
        />
      </FormRow>
      <FormRow>
        <Input
          autoComplete={'new-password'}
          label={t('confirm-password')}
          type="password"
          placeholder={t('confirm-password')}
          {...register('confirmPassword', {
            required: t('must-not-empty') as string,
            validate: (value) =>
              value === watch('password') ||
              (t('confirm-password-validation') as string),
          })}
          helperText={errors.confirmPassword?.message}
          hasError={Boolean(errors?.confirmPassword)}
          required
        />
      </FormRow>
    </>
  );
};
