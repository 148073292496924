import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { StaffSettingsTimePlanningTree } from 'configs/RoutesConfig';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import CheckboxInput from '../../../../components/common/Forms/CheckboxInput';
import { ControlledDatePickerInput } from '../../../../components/common/Forms/controlled/ControlledDatePickerInput';
import { ControlledSelect } from '../../../../components/common/Forms/controlled/ControlledSelect';
import {
  FormActionsStyle,
  FormBody,
  FormHeader,
  FormRow,
  FormTitle,
} from '../../../../components/common/Forms/Forms.styled';
import CommonInput from '../../../../components/common/Forms/Input';
import { CardLoader } from '../../../../components/common/Loading';
import {
  SITE_WEEK_DAYS,
  STAFF_CLOSE_HOUR_IN_SECONDS,
  STAFF_OPEN_HOUR_IN_SECONDS,
} from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useMaxAndMinFutureDateForStaffBooking } from '../../../../hooks/patient/booking/useMaxAndMinFutureDateForBooking';
import { useResourceTypesWithCubiclesAssigned } from '../../../../hooks/resourceTypes/useResourceTypesWithCubiclesAssigned';
import {
  useSiteIntAttributes,
  useSiteNumericArrayAttribute,
} from '../../../../hooks/useAttribute';
import { SelectOption } from '../../../../types/common';
import {
  formatSecondsOfDayIntoTime,
  getServerTime,
  isAfterOrSameDay,
  isBeforeOrSameDay,
  startOfDay,
} from '../../../../utils/dateUtil';
import { StaffLayout } from '../../StaffLayout';
import {
  MASS_CANCEL_ALL_CALENDAR_TEMPLATES_VALUE,
  MassCancelFormValues,
  useMassCancelValidation,
} from './StaffMassCancelHooks';
import { StaffMassCancelConfirmDialog } from './StaffMassCancelConfirmDialog';

export default function StaffMassCancel() {
  const { t } = useCommonTranslation();
  const [formValuesToConfirm, setFormValuesToConfirm] =
    useState<MassCancelFormValues>();

  const [openHourInSeconds, closeHourInSeconds] = useSiteIntAttributes(
    STAFF_OPEN_HOUR_IN_SECONDS,
    STAFF_CLOSE_HOUR_IN_SECONDS,
  );

  const {
    isLoading: isOpeningHoursLoading,
    openTime,
    closeTime,
  } = useMemo(() => {
    if (
      typeof openHourInSeconds !== 'number' ||
      typeof closeHourInSeconds !== 'number'
    ) {
      return { isLoading: true };
    }
    return {
      isLoading: false,
      openTime: formatSecondsOfDayIntoTime(openHourInSeconds),
      closeTime: formatSecondsOfDayIntoTime(closeHourInSeconds),
    };
  }, [openHourInSeconds, closeHourInSeconds]);

  const {
    resourceTypesWithCubicleAssigned: resourceTypes,
    isLoading: isResourceTypesLoading,
  } = useResourceTypesWithCubiclesAssigned();
  const hasMultipleResourceTypes = Boolean(
    resourceTypes && resourceTypes.length > 1,
  );

  const isLoading = isOpeningHoursLoading || isResourceTypesLoading;

  const resourceTypeOptions = useMemo(() => {
    if (!resourceTypes) {
      return [];
    }
    const options: SelectOption<string>[] = [
      {
        key: MASS_CANCEL_ALL_CALENDAR_TEMPLATES_VALUE,
        label: t('all'),
        value: MASS_CANCEL_ALL_CALENDAR_TEMPLATES_VALUE,
      },
      ...resourceTypes.map((resourceType) => ({
        key: resourceType.id,
        label: resourceType.name,
        value: resourceType.id,
      })),
    ];
    return options;
  }, [resourceTypes, t]);

  const validateResolver = useMassCancelValidation({
    hasMultipleResourceTypes,
  });
  const {
    register,
    control,
    formState,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<MassCancelFormValues>({
    resolver: validateResolver,
    defaultValues: {
      fullDay: true,
      fromTime: openTime,
      toTime: closeTime,
      clearApptTemplate: true,
    },
  });
  const values = watch();
  const { fullDay } = values;

  const openMassCancelConfirmDialog = () => {
    handleSubmit(() => {
      setFormValuesToConfirm(values);
    })();
  };

  const { maxDate: maxSite } = useMaxAndMinFutureDateForStaffBooking();
  const weekDays = useSiteNumericArrayAttribute(SITE_WEEK_DAYS);

  const isDayAvailable = useCallback(
    (date: Date) => {
      return (
        isAfterOrSameDay(startOfDay(date), startOfDay(getServerTime())) &&
        isBeforeOrSameDay(startOfDay(date), startOfDay(maxSite)) &&
        weekDays?.includes(date.getDay())
      );
    },
    [maxSite, weekDays],
  );

  return (
    <StaffLayout>
      <PageHelmet title={t('mass-cancel')} />
      <Grid>
        <Col md={5}>
          <Card>
            {isLoading ? (
              <CardLoader />
            ) : (
              <>
                <LinkCardTabs tabs={StaffSettingsTimePlanningTree(t)} />
                <FormHeader>
                  <FormTitle>{t('mass-cancel')}</FormTitle>
                </FormHeader>
                <FormBody>
                  <FormRow>
                    <ControlledDatePickerInput
                      control={control}
                      formState={formState}
                      helperText={errors.date?.message}
                      hasError={Boolean(errors.date)}
                      name="date"
                      label={t('date')}
                      placeholder={t('pick-date')}
                      filterDate={isDayAvailable}
                    />
                    <CheckboxInput
                      onlyInRow
                      label={t('mass-cancel-full-day')}
                      key="fullDay"
                      id="fullDay"
                      isToggle={false}
                      {...register('fullDay')}
                      checked={fullDay}
                    />
                  </FormRow>
                  {!fullDay && (
                    <FormRow>
                      <CommonInput
                        {...register('fromTime')}
                        placeholder={t('hour-from')}
                        label={t('hour-from')}
                        hasError={Boolean(errors.fromTime)}
                        helperText={errors?.fromTime?.message}
                        disabled={fullDay}
                      />
                      <CommonInput
                        {...register('toTime')}
                        placeholder={t('hour-to')}
                        label={t('hour-to')}
                        hasError={Boolean(errors.toTime)}
                        helperText={errors?.toTime?.message}
                        disabled={fullDay}
                      />
                    </FormRow>
                  )}
                  {hasMultipleResourceTypes && (
                    <FormRow>
                      <ControlledSelect
                        control={control}
                        formState={formState}
                        helperText={errors.resourceTypeId?.message}
                        hasError={Boolean(errors.resourceTypeId)}
                        name="resourceTypeId"
                        label={t('calendar-template')}
                        options={resourceTypeOptions}
                      />
                    </FormRow>
                  )}
                  <FormRow>
                    <CheckboxInput
                      label={t('mass-cancel-clear-appt-template')}
                      checked={values.clearApptTemplate}
                      {...register('clearApptTemplate')}
                    />
                  </FormRow>
                  <FormActionsStyle align="center" topMargin={true}>
                    <CommonButton
                      variant="danger"
                      type="button"
                      onClick={() => openMassCancelConfirmDialog()}
                    >
                      {t('submit-mass-cancel')}
                    </CommonButton>
                  </FormActionsStyle>
                </FormBody>
              </>
            )}
          </Card>
        </Col>
      </Grid>
      {formValuesToConfirm && (
        <StaffMassCancelConfirmDialog
          formValues={formValuesToConfirm}
          onClose={() => setFormValuesToConfirm(undefined)}
        />
      )}
    </StaffLayout>
  );
}
