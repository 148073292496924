import { useAuth } from 'context/AuthContext';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import { SignupFormValues } from 'types/users';
import { errorToast } from 'utils/toast';
import { DEFAULT_SMS_OPT_IN, getUserFormData } from 'utils/user';
import { FormWrapper } from './SignupForm.styled';
import { useTrust } from '../../../../query/trusts';
import { useQuerySite } from '../../../../query/sites';
import { useGPSurgeriesOptions } from '../../../../hooks/referrals/useGPSurgeriesOptions';
import { SignupStepHospitalSelect } from './SignupStepHospitalSelect';
import { CardLoader } from '../../../common/Loading';
import { SignupStepContactDetails } from './SignupStepContactDetails';
import { SignupStepPatientInfo } from './SignupStepPatientInfo';
import { FormActionsStyle } from '../../../common/Forms/Forms.styled';
import {
  CommonButton,
  CommonButton as Button,
} from '../../../common/Forms/Button';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useHistory } from 'react-router';
import {
  LOGIN_HASH_BOOK_AT_SITE_ID_PARAM,
  LOGIN_ROUTE,
  SIGN_UP_HASH_SITE_ID_PARAM,
} from '../../../../routes/AppRoutes';
import { useHashParams } from '../../../../hooks/router/useHashParams';
import { gpSurgeryRequiredTrust } from '../../../../utils/trustUtil';
import { SignupStepEmailPwd } from './SignupStepEmailPwd';
import { STEPS, useSignupFormSteps } from './useSignupFormSteps';
import { useVerifyEmailInBackend } from '../../../../hooks/user/register/useVerifyEmailInBackend';
import {
  useRegisterFirebaseAlreadySignedUp,
  useRegisterUserNotInFirebase,
} from '../../../../hooks/user/register/useRegister';
import { useI18N } from '../../../../context/I18NContext';

export const SignupForm = () => {
  const { firebaseUser, logout } = useAuth();
  const { t } = useCommonTranslation();
  const { push } = useHistory();
  const { language } = useI18N();
  const [isLoadingState, setIsLoading] = useState(false);
  const userInFirebaseNotSangixDb = useMemo(
    () => !!firebaseUser,
    [firebaseUser],
  );

  const [siteIdForSignUp, siteIdForBook] = useHashParams(
    SIGN_UP_HASH_SITE_ID_PARAM,
    LOGIN_HASH_BOOK_AT_SITE_ID_PARAM,
  );
  const registrationSiteIdPassed = siteIdForSignUp || siteIdForBook;

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState,
    trigger,
    watch,
    getValues,
  } = useForm<SignupFormValues>({
    defaultValues: {
      active_site_id: registrationSiteIdPassed,
      sms_optin: DEFAULT_SMS_OPT_IN,
    },
  });

  const { isLoading: isEmailCheckLoading, verifyEmailOnBackend } =
    useVerifyEmailInBackend();

  const removeFirebaseUserIfExist = useCallback(async () => {
    if (userInFirebaseNotSangixDb) {
      await logout();
      try {
        // Attempt to delete user, ignore error
        firebaseUser?.delete();
      } catch (e) {}
    }
  }, [userInFirebaseNotSangixDb, logout, firebaseUser]);

  const onAbandoningSignUp = useCallback(async () => {
    await removeFirebaseUserIfExist();
    push(LOGIN_ROUTE);
  }, [push, removeFirebaseUserIfExist]);

  const activeSiteId = watch('active_site_id');

  const { registerWithFirebaseSignup } = useRegisterUserNotInFirebase();
  const { registerFirebaseAlreadySignedUp } =
    useRegisterFirebaseAlreadySignedUp();

  const finishRegistration = useCallback(
    async (values: SignupFormValues) => {
      setIsLoading(true);
      const { firebaseUser: emailPwd, userInfo } = getUserFormData({
        ...values,
        active_site_id: activeSiteId,
      });
      // Set i18n language to the user's language
      userInfo.language = language;
      try {
        if (firebaseUser) {
          const completeSignupEmail = {
            email: userInFirebaseNotSangixDb
              ? firebaseUser?.email || firebaseUser?.user?.email
              : userInfo.email,
          };
          await registerFirebaseAlreadySignedUp({
            ...userInfo,
            ...completeSignupEmail,
          });
        } else {
          await registerWithFirebaseSignup(
            {
              ...emailPwd,
              email: values.email,
            },
            userInfo,
          );
        }
      } catch (error) {
        errorToast(error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      language,
      activeSiteId,
      userInFirebaseNotSangixDb,
      firebaseUser,
      registerFirebaseAlreadySignedUp,
      registerWithFirebaseSignup,
    ],
  );

  const { data: activeSite, isLoading: isSiteLoading } = useQuerySite(
    activeSiteId,
    {
      enabled: !!activeSiteId,
      onError: (err) => {
        errorToast(err);
      },
      onSuccess: async (data) => {
        if (registrationSiteIdPassed !== data.data.id) {
          await onNextClicked(getValues());
        }
      },
    },
  );

  const { onNextClicked, step, onBackStepClicked } = useSignupFormSteps({
    registeringForSpecificSite: Boolean(
      registrationSiteIdPassed && activeSite?.data,
    ),
    userInFirebaseNotSangixDb,
    trigger,
    finishRegistration,
    onAbandoningSignUp,
    verifyEmailOnBackend,
  });

  const { data: activeTrust, isLoading: isTrustLoading } = useTrust(
    activeSite?.data?.trust_id,
  );

  const isLoading =
    isLoadingState || isSiteLoading || isTrustLoading || isEmailCheckLoading;

  const { gpSurgeriesOptions: gpOptions } = useGPSurgeriesOptions({
    gpFilters: {
      trustIDs: activeTrust && [activeTrust.id],
    },
    options: {
      enabled: !!activeTrust && gpSurgeryRequiredTrust(activeTrust),
    },
    noToken: true,
    sortWithGPIdFirst: activeTrust?.unknown_gp_surgery_id,
  });

  useEffectOnce(() => {
    if (userInFirebaseNotSangixDb) {
      setValue('email', firebaseUser.email);
    }
  });

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(onNextClicked)} autoComplete={'false'}>
        {isLoading ? (
          <CardLoader />
        ) : (
          <>
            {step === STEPS.EMAIL && (
              <SignupStepEmailPwd
                errors={formState.errors}
                register={register}
                watch={watch}
                trigger={trigger}
                getValues={getValues}
              />
            )}
            {step === STEPS.SELECT_HOSPITAL && (
              <SignupStepHospitalSelect setValue={setValue} />
            )}
            {activeSite && activeTrust && (
              <>
                {step === STEPS.CONTACT_DETAILS && (
                  <SignupStepContactDetails
                    errors={formState.errors}
                    register={register}
                    control={control}
                    trust={activeTrust}
                    site={activeSite.data}
                  />
                )}
                {step === STEPS.PATIENT_INFO && (
                  <SignupStepPatientInfo
                    control={control}
                    errors={formState.errors}
                    register={register}
                    gpOptions={gpOptions}
                    trust={activeTrust}
                    site={activeSite.data}
                  />
                )}
              </>
            )}
          </>
        )}
        <FormActionsStyle>
          {step !== STEPS.SELECT_HOSPITAL && (
            <Button variant="primary" type="submit" disabled={isLoading}>
              {step === STEPS.PATIENT_INFO ? t('signup') : t('next')}
            </Button>
          )}
          <CommonButton
            type={'button'}
            variant="secondary"
            onClick={onBackStepClicked}
          >
            {t('back')}
          </CommonButton>
        </FormActionsStyle>
      </form>
    </FormWrapper>
  );
};
