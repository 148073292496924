import React, { forwardRef, ReactElement } from 'react';
import { Range } from 'react-range';
import {
  InputRangePrefix,
  InputRangePrefixWithSliderStyle,
  InputRangeThumb,
  InputRangeTopRight,
  InputRangeTrackHolder,
  InputRangeTrackTrack,
  InputRangeWrapper,
} from './Forms.styled';
import { toRemStr } from '../../../utils/helpers';

export type GlobalInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'disabled' | 'onChange'
> & {
  min?: number;
  step?: number;
  max: number;
  prefix?: string;
  defaultValue?: number;
  disabled?: boolean;
  prefixType?: 'small' | 'big';
  color: string;
  onChange: (values: number) => void;
  showMaxTopRight?: boolean;
  availableRange?: {
    max: number;
    trackColor?: string;
  };
};

export default forwardRef<Range, GlobalInputProps>(function InputRange(
  {
    color = 'var(--greenaccent)',
    prefixType = 'small',
    disabled,
    className,
    defaultValue,
    min = 0,
    max,
    prefix,
    onChange,
    value,
    step = 1,
    showMaxTopRight = false,
    availableRange,
  },
  ref,
): ReactElement {
  const availableRangeWidth = availableRange
    ? `${(availableRange.max / max) * 100}%`
    : '100%';
  const countedMax = availableRange ? availableRange.max : max;
  const valueTopped = value && Number(value) > countedMax ? countedMax : value;
  return (
    <InputRangeWrapper className={className} showMaxTopRight={showMaxTopRight}>
      <InputRangePrefixWithSliderStyle>
        {Boolean(prefix) && (
          <InputRangePrefix prefixType={prefixType}>{prefix}</InputRangePrefix>
        )}
        <InputRangeTrackHolder>
          <InputRangeTrackTrack />
          {availableRange && max !== 0 && (
            <InputRangeTrackTrack
              style={{
                backgroundColor: availableRange.trackColor || color,
                width: availableRangeWidth,
              }}
            />
          )}
          <div
            style={{
              margin: `0 ${toRemStr(10)}`,
            }}
          >
            {countedMax > min ? (
              <Range
                disabled={disabled}
                ref={ref}
                max={countedMax}
                step={step}
                min={min}
                values={[valueTopped as number]}
                onChange={([values]) => {
                  onChange(values);
                }}
                renderTrack={({ props, children }) => {
                  return (
                    <div
                      {...props}
                      style={{
                        height: 'var(--s2)',
                        width: availableRangeWidth,
                      }}
                    >
                      {children}
                    </div>
                  );
                }}
                renderThumb={({ props, value }) => {
                  return (
                    <InputRangeThumb {...props} color={color}>
                      {value}
                    </InputRangeThumb>
                  );
                }}
              />
            ) : (
              <InputRangeThumb
                style={{
                  zIndex: 0,
                  position: 'absolute',
                  bottom: -6,
                  left: -0.5,
                  cursor: 'not-allowed',
                }}
                color={'var(--disabled)'}
              >
                {value}
              </InputRangeThumb>
            )}
          </div>
        </InputRangeTrackHolder>
      </InputRangePrefixWithSliderStyle>
      {showMaxTopRight && <InputRangeTopRight> {max}</InputRangeTopRight>}
    </InputRangeWrapper>
  );
});
