import {
  addUserRole,
  changeUserPassword,
  createOtherUserMember,
  createUser,
  deleteUser,
  getAllUsers,
  getUser,
  getUserAccount,
  mergeUser,
  postMergeMember,
  removeUserRole,
  updateOtherUserMember,
  updateUser,
} from '../services/users';
import {
  APIUserCreate,
  MergeMemberFormValues,
  OtherUserMemberCreateFromValues,
  OtherUserMemberUpdateFromValues,
  RoleUpdateObject,
  UserData,
  UserFormValues,
  UserPasswordFormValues,
  UserProfileRegisterUpdateFormValues,
  UsersFilters,
} from '../types/users';
import {
  CacheAndStaleTimeForUnlikelyChanged,
  CacheAndStaleTimeShort,
  UseMutationOptionsWithError,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';
import { useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import {
  updateUserActiveSite,
  updateUserProfile,
  upgradeUser,
} from '../services/userProfile';
import { UserUpgradeResponse } from '../types/authentication';

export const USERS_QUERY_KEY = 'all-users';
const USER_PROFILE_KEY = 'userProfile';

export const useUsers = (filters?: UsersFilters) => {
  return useQueryWithToastError(
    [USERS_QUERY_KEY, filters],
    () => getAllUsers(filters),
    {
      ...CacheAndStaleTimeShort,
      /**
       * Backend treats empty string as missing attribute value and skips roleID
       * filtration in such case. Therefore we disable the query to have an empty list.
       */
      enabled: filters?.roleID !== '',
    },
  );
};

export const useUser = (id?: string) => {
  return useQueryWithToastError(
    [
      USERS_QUERY_KEY,
      {
        id: id,
      },
    ],
    () => getUser(id!!),
    {
      ...CacheAndStaleTimeShort,
      enabled: !!id,
    },
  );
};

export const useCreateUserMutation = (
  options?: UseMutationOptionsWithError<any, any, APIUserCreate>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<APIUserCreate, AxiosResponse<UserData>>(
    [USERS_QUERY_KEY],
    (user) => createUser(user),
    {
      onSettled: () => client.invalidateQueries([USERS_QUERY_KEY]),
      ...options,
    },
  );
};

export const useChangeUserPasswordMutation = () => {
  return useMutationWithToastError<{ id: string } & UserPasswordFormValues>(
    [USERS_QUERY_KEY],
    ({ id, ...rest }) => changeUserPassword(id, rest),
  );
};

export const useUpdateUserMutation = (
  options?: UseMutationOptionsWithError<
    AxiosResponse<string>,
    any,
    UserFormValues
  >,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<UserFormValues, AxiosResponse<string>>(
    [USERS_QUERY_KEY],
    (user) => updateUser(user),
    {
      onSettled: () => client.invalidateQueries([USERS_QUERY_KEY]),
      ...options,
    },
  );
};

export const useUserDeleteMutation = (
  options?: UseMutationOptionsWithError<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [USERS_QUERY_KEY],
    (id) => deleteUser(id),
    {
      onSettled: () => client.invalidateQueries([USERS_QUERY_KEY]),
      ...options,
    },
  );
};

interface MergeUserProps {
  sourceUserId: string;
  targetUserId: string;
}

export const useUserMergeMutation = (
  options?: UseMutationOptionsWithError<any, any, MergeUserProps>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<MergeUserProps>(
    [USERS_QUERY_KEY],
    ({ sourceUserId, targetUserId }) => mergeUser(sourceUserId, targetUserId),
    {
      onSettled: () => client.invalidateQueries([USERS_QUERY_KEY]),
      ...options,
    },
  );
};

export const useUpdateUserProfileMutation = (
  options?: UseMutationOptionsWithError<
    any,
    any,
    UserProfileRegisterUpdateFormValues
  >,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<UserProfileRegisterUpdateFormValues>(
    [USERS_QUERY_KEY, 'Profile'],
    (profile) => updateUserProfile(profile),
    {
      onSettled: () => client.invalidateQueries([USER_PROFILE_KEY]),
      ...options,
    },
  );
};

export const useCreateOtherUserMemberMutation = (
  options?: UseMutationOptionsWithError<
    any,
    any,
    OtherUserMemberCreateFromValues
  >,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<OtherUserMemberCreateFromValues>(
    [USERS_QUERY_KEY],
    (profile) => createOtherUserMember(profile),
    {
      onSettled: () => client.invalidateQueries([USERS_QUERY_KEY]),
      ...options,
    },
  );
};

export const useUpdateOtherUserMemberMutation = (
  options?: UseMutationOptionsWithError<
    any,
    any,
    OtherUserMemberUpdateFromValues
  >,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<OtherUserMemberUpdateFromValues>(
    [USERS_QUERY_KEY],
    (profile) => updateOtherUserMember(profile),
    {
      onSettled: () => client.invalidateQueries([USERS_QUERY_KEY]),
      ...options,
    },
  );
};

export const useMergeMemberMutation = (
  options?: UseMutationOptionsWithError<any, any, MergeMemberFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<MergeMemberFormValues>(
    [USERS_QUERY_KEY],
    (data) => postMergeMember(data),
    {
      onSettled: () => client.invalidateQueries([USERS_QUERY_KEY]),
      ...options,
    },
  );
};

export const useGetUserAccount = (accountId: string) => {
  return useQueryWithToastError(
    [USERS_QUERY_KEY, accountId],
    () => getUserAccount(accountId),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
    },
  );
};

export const useUpdateUserActiveSiteMutation = (
  options?: UseMutationOptionsWithError<any, any, string>,
) => {
  return useMutationWithToastError<string>(
    [USERS_QUERY_KEY, 'updateSite'],
    (profile) => updateUserActiveSite(profile),
    {
      ...options,
    },
  );
};

export const useAddUserRoleMutation = (
  options?: UseMutationOptionsWithError<any, any, RoleUpdateObject>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<RoleUpdateObject>(
    [USERS_QUERY_KEY, 'setRole'],
    (role) => addUserRole(role),
    {
      onSettled: () => client.invalidateQueries([USERS_QUERY_KEY]),
      ...options,
    },
  );
};

export const useRemoveUserRoleMutation = (
  options?: UseMutationOptionsWithError<any, any, RoleUpdateObject>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<RoleUpdateObject>(
    [USERS_QUERY_KEY, 'removeRole'],
    (role) => removeUserRole(role),
    {
      onSettled: () => client.invalidateQueries([USERS_QUERY_KEY]),
      ...options,
    },
  );
};

export const useUpgradeUserMutation = (
  options?: UseMutationOptionsWithError<any, any, { email: string }>,
) => {
  return useMutationWithToastError<
    { email: string },
    AxiosResponse<UserUpgradeResponse>
  >([USERS_QUERY_KEY, 'upgradeUser'], (email) => upgradeUser(email), {
    ...options,
  });
};
