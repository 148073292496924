import { TFunction } from 'react-i18next';
import { DATE_FNS_TIME, parseDate } from './dateUtil';
import { isValid } from 'date-fns';

export const parseAndValidateCorrectTimeAndDivisibleBy5Minutes = ({
  input,
  t,
}: {
  input: string;
  t: TFunction;
}):
  | { message: string; parsedDate?: undefined }
  | { message?: undefined; parsedDate: Date } => {
  /**
   * The parse will accept things like 09:1 and think it is 09:01.
   * This will ensure there are two minutes if the format is correct.
   */
  if (input.split(':').pop()?.length !== 2) {
    return { message: t('must-valid-24h-time') as string };
  }
  const parsedInputDate = parseDate(input, DATE_FNS_TIME);
  if (!isValid(parsedInputDate)) {
    return { message: t('must-valid-24h-time') as string };
  }
  if (parsedInputDate.getMinutes() % 5 !== 0) {
    return { message: t('must-valid-time-divisible-5-mins') as string };
  }
  return { parsedDate: parsedInputDate };
};
