import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { PrevNextWeekPicker } from '../../../../components/InlineCalendar/PrevNextWeekPicker';
import { WeekInlineCalendarPickerWrapper } from '../../../../components/InlineCalendar/WeekDayPicker.styled';
import { PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE } from '../../../../configs/siteAndTrustAttributes';
import { useMaxAndMinFutureDateForBookingForOnlineBooking } from '../../../../hooks/patient/booking/useMaxAndMinFutureDateForBooking';
import { useSpecificSiteStringAttributes } from '../../../../hooks/useSpecificSiteAttribute';
import { WithSite } from '../../../../types/sites';
import { formatDateOnlySite } from '../../../../utils/dateUtil';
import {
  PatientBookingStageTitle,
  PatientBookingStageTitle2,
} from '../PatientBooking.styled';
import { PatientBookingControlProps } from './PatientBookingControl';

export const PatientBookingPickDayNoApptsLastPeriod = ({
  site,
  searchPeriod: { toDate, fromDate },
  updateBooking,
  firstFoundInWeek,
}: {
  searchPeriod: {
    fromDate: Date;
    toDate: Date;
  };
} & PatientBookingControlProps &
  WithSite) => {
  const { t } = useCommonTranslation();
  const { minDate, maxDate } =
    useMaxAndMinFutureDateForBookingForOnlineBooking();
  const [noApptMessage] = useSpecificSiteStringAttributes(
    site,
    PATIENT_BOOKING_NO_APPTS_IN_LAST_PERIOD_MESSAGE,
  );

  return (
    <>
      <PatientBookingStageTitle>
        {noApptMessage || t('pick-day-no-appts-last-period')}
      </PatientBookingStageTitle>
      <PatientBookingStageTitle2>
        {t('pick-day-searching-range', {
          from: formatDateOnlySite(fromDate),
          to: formatDateOnlySite(toDate),
        })}
      </PatientBookingStageTitle2>

      <WeekInlineCalendarPickerWrapper>
        <PrevNextWeekPicker
          selectedDate={firstFoundInWeek}
          onSelectDate={(date) => updateBooking({ calendarDateShown: date })}
          iconOnly
          minDate={minDate}
          maxDate={maxDate}
        />
      </WeekInlineCalendarPickerWrapper>
    </>
  );
};
