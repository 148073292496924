import { useUpgradeUserMutation } from '../../../query/users';
import { useCallback } from 'react';
import { UserUpgradeResponseStatus } from '../../../types/authentication';

export const useVerifyEmailInBackend = () => {
  const { mutateAsync, isLoading } = useUpgradeUserMutation();
  const verifyEmailOnBackend = useCallback(
    async (email: string): Promise<UserUpgradeResponseStatus> => {
      const response = await mutateAsync({ email });
      return response.data.status;
    },
    [mutateAsync],
  );
  return { isLoading, verifyEmailOnBackend };
};
