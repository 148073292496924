import { useCallback, useState } from 'react';
import { UserFormValues } from '../../types/users';
import { getUserFormData } from '../../utils/user';
import { getUserProfile, updateUserProfile } from '../../services/userProfile';
import { useAuth } from '../../context/AuthContext';

export const useUpdateAuthUser = () => {
  const { sangixUser, updateAuthUser } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const update = useCallback(
    async (userProfileUpdateValues: UserFormValues) => {
      try {
        setIsLoading(true);
        if (userProfileUpdateValues && sangixUser) {
          const { userInfo } = getUserFormData({
            ...userProfileUpdateValues,
          });
          await updateUserProfile({
            ...userInfo,
            email: userInfo.email || sangixUser.email,
          });
        }
        const { data } = await getUserProfile();
        updateAuthUser({
          sangixUser: data,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [updateAuthUser, sangixUser],
  );

  return {
    updateAuthUser: update,
    isLoading,
  };
};
